import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="15" height="15" viewBox="0 0 15 15" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.59082" cy="7.45459" r="7" />
      <path d="M6.98438 7.45468C6.98437 7.55808 7.00474 7.66046 7.04431 7.75598C7.08387 7.8515 7.14187 7.9383 7.21498 8.01141C7.28809 8.08452 7.37488 8.14251 7.4704 8.18208C7.56593 8.22165 7.66831 8.24201 7.7717 8.24201C7.87509 8.24201 7.97747 8.22165 8.073 8.18208C8.16852 8.14251 8.25531 8.08452 8.32842 8.01141C8.40153 7.9383 8.45953 7.8515 8.49909 7.75598C8.53866 7.66046 8.55903 7.55808 8.55903 7.45468C8.55903 7.24587 8.47608 7.04561 8.32842 6.89796C8.18077 6.75031 7.98051 6.66736 7.7717 6.66736C7.56289 6.66736 7.36263 6.75031 7.21498 6.89796C7.06733 7.04561 6.98438 7.24587 6.98438 7.45468Z" fill="white" />
      <path d="M9.95898 7.45468C9.95898 7.55808 9.97935 7.66046 10.0189 7.75598C10.0585 7.8515 10.1165 7.9383 10.1896 8.01141C10.2627 8.08452 10.3495 8.14251 10.445 8.18208C10.5405 8.22165 10.6429 8.24201 10.7463 8.24201C10.8497 8.24201 10.9521 8.22165 11.0476 8.18208C11.1431 8.14251 11.2299 8.08452 11.303 8.01141C11.3761 7.9383 11.4341 7.8515 11.4737 7.75598C11.5133 7.66046 11.5336 7.55808 11.5336 7.45468C11.5336 7.35129 11.5133 7.24891 11.4737 7.15339C11.4341 7.05786 11.3761 6.97107 11.303 6.89796C11.2299 6.82485 11.1431 6.76686 11.0476 6.72729C10.9521 6.68772 10.8497 6.66736 10.7463 6.66736C10.6429 6.66736 10.5405 6.68772 10.445 6.72729C10.3495 6.76686 10.2627 6.82485 10.1896 6.89796C10.1165 6.97107 10.0585 7.05786 10.0189 7.15339C9.97935 7.24891 9.95898 7.35129 9.95898 7.45468Z" fill="white" />
      <path d="M4.00879 7.45468C4.00879 7.6635 4.09174 7.86376 4.23939 8.01141C4.38704 8.15906 4.5873 8.24201 4.79611 8.24201C5.00493 8.24201 5.20519 8.15906 5.35284 8.01141C5.50049 7.86376 5.58344 7.6635 5.58344 7.45468C5.58344 7.24587 5.50049 7.04561 5.35284 6.89796C5.20519 6.75031 5.00493 6.66736 4.79611 6.66736C4.5873 6.66736 4.38704 6.75031 4.23939 6.89796C4.09174 7.04561 4.00879 7.24587 4.00879 7.45468Z" fill="white" />
    </Svg>
  );
};

export default Icon;
