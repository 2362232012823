// import farmTokens from './farmTokens'
// import { FarmConfig } from './types'

// const farms: FarmConfig[] = [
//   /** KNS-OKT */
//   {
//     pid: 5,
//     lpSymbol: 'MGS-OKT LP',
//     multiplier: '40X',
//     lpAddresses: {
//       65: '0x8b61e6513233b2c1d2d7cdabd7d6423cace832b3',
//       56: '',
//     },
//     token: farmTokens.kns,
//     quoteToken: farmTokens.okt,
//   },
// ]

// export default farms

import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [

]

export default farms
