import React, { useCallback, useMemo, useState } from 'react'
import { Currency, CurrencyAmount } from '@mangoswap-libs/sdk'
import { DatePicker } from 'antd'
import moment  from 'moment'
import { TransactionResponse } from '@ethersproject/providers'
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import CardNav from '../../components/CardNav'
import Container from '../../components/Container'
import AppBody from '../AppBody'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import ActionButton from '../../uikit/components/Button/ActionButton'
import PageHeader from '../../components/PageHeader'
import useIntl from '../../hooks/useIntl'
import { AutoColumn } from '../../components/Column'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { tryParseAmount } from '../../state/swap/hooks'
import { calculateGasMargin, getLockContract } from '../../utils'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useTransactionAdder } from '../../state/transactions/hooks'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import Row, { RowFlat } from '../../components/Row'
import { Button, Input, Text as UIKitText } from '../../uikit'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { LOCK_ADDRESS } from '../../constants'
import { useLockList } from '../../hooks/useLock'

export default function Lock() {
  const intl = useIntl()
  const { account, chainId, library } = useActiveWeb3React()
  const [typeAmount, setTypeAmount] = useState<CurrencyAmount | undefined>()
  const [time, setTime] = useState<number | undefined>()
  const [title, setTile] = useState<string | undefined>()
  const [selectTime, setSelectTime] = useState<string | undefined>()
  const [currency, setCurrency] = useState<Currency | undefined>()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [txHash, setTxHash] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const addTransaction = useTransactionAdder()

  const [approval, approveACallback] = useApproveCallback(typeAmount, LOCK_ADDRESS)
  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setCurrency(inputCurrency)
    },
    []
  )
  const balance = useCurrencyBalance(account, currency)
  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(balance)

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      setTypeAmount(maxAmountInput)
    }
  }, [maxAmountInput])

  const handleTypeInput = useCallback(
    (value: string) => {
      setTypeAmount(tryParseAmount(value, currency))
    },
    [currency]
  )

  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    console.log('Selected Time: ', value)
    console.log('Formatted Selected Time: ', dateString)
  }

  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', parseInt(((Date.parse(value.toString()) - Date.now()) / 1000).toString()))
    setSelectTime(moment(value.toString()).format('YYYY/MM/DD, h:mm:ss a'))
    setTime(parseInt((Date.parse(value.toString()) / 1000).toString()))
  }

  const onLock = async () => {
    if (!chainId || !library || !account) return
    // console.log('---getRouterContract--- ', chainId, library,  account)
    const contract = getLockContract(library, account)
    // console.log('router', router)

    if (!typeAmount || !currency || !time) {
      return
    }

    const estimate = contract.estimateGas.lock
    const method: (...args: any) => Promise<TransactionResponse> = contract.lock
    const args: Array<string | string[] | number> = [wrappedCurrency(currency, chainId).address, typeAmount.raw.toString(), '1234', time.toString()]

    setAttemptingTxn(true)
    console.log(args)
    await estimate(...args)
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then((response) => {
          // console.log('---response--', response)
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Lock ${currency.symbol}`
          })

          setTxHash(response.hash)
        })
      )
      .catch((e) => {
        // console.log('---Error--', e)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (e?.code !== 4001) {
          console.error(e)
        }
      })
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      setTypeAmount(undefined)
    }
    setTxHash('')
  }, [setTypeAmount, txHash])

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    console.log('tag-->', current)
    return current && (current < moment().endOf('day'))
  }

  const modalHeader = () => {
    return (
      <AutoColumn gap='20px'>
        <RowFlat style={{ marginTop: '20px' }}>
          <UIKitText fontSize='48px' mr='8px'>
            {typeAmount?.toSignificant(6)} {currency?.symbol}
          </UIKitText>
        </RowFlat>
        <Row>
          <UIKitText fontSize='24px'>
            {`${selectTime}`}
          </UIKitText>
        </Row>
        <UIKitText small textAlign='left' padding='8px 0 0 0 ' style={{ fontStyle: 'italic' }}>
          {intl({
            id: 'lock.tip',
            data: { amount: typeAmount?.toSignificant(), token: currency.symbol, time: selectTime }
          })}
        </UIKitText>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <>
        <Button mt='20px' onClick={onLock} variant='primary'>
          {intl('lock.title')}
        </Button>
      </>
    )
  }

  const btnContent = useMemo(() => {
    if (!account) {
      return intl('swap.connectWallet2')
    }
    if (!currency) {
      return intl('find.selectToken')
    }
    if (!typeAmount) {
      return intl('swap.enterAmount')
    }
    if (!time) {
      return intl('lock.time')
    }
    if (approval === ApprovalState.NOT_APPROVED) {
      return intl('swap.approve')
    }
    return intl('lock.title')
  }, [account, typeAmount, approval, currency, intl, time])

  return (
    <Container>
      <CardNav activeIndex={2} />
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => (
          <ConfirmationModalContent
            title={intl('lock.title')}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={
          intl({
            id:
              'lock.locking',
            data: { amount: typeAmount?.toSignificant(), token: currency?.symbol, time: selectTime }
          })}
      />

      <AppBody>
        <PageHeader
          title={intl('lock.title', 'Lock')}
          description={intl('lock.desc', 'Lock token or liquidity')}
        />
        <AutoColumn gap='20px' style={{ padding: '20px 20px' }}>
          <CurrencyInputPanel hideLogo currency={currency} onCurrencySelect={handleInputSelect} onMax={handleMaxInput}
                              value={typeAmount?.toSignificant()} onUserInput={handleTypeInput} showMaxButton
                              id='lock-amount' />
          <DatePicker style={{ borderRadius: '8px' }} disabledDate={disabledDate} showTime onChange={onChange}
                      onOk={onOk} />
          <Input value={title} onChange={(e)=>{setTile(e.target.value)}} placeholder={intl('title')} style={{ borderRadius: '8px' }} />
          <ActionButton
            disableAction={!account || !typeAmount || !currency || !time}
            pending={approval === ApprovalState.PENDING}
            pendingText={intl('swap.approving')}
            actionText={btnContent}
            onAction={approval === ApprovalState.NOT_APPROVED ? approveACallback : () => setShowConfirm(true)} />
        </AutoColumn>
      </AppBody>
    </Container>)
}
