import { useActiveWeb3React } from './index'
import { getLockContract } from '../utils'
import { useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'

export interface LockData {
  index: string
  beginTime: number
  endTime: number
  hasUnlocked: boolean
  isLP: boolean
  title: string
  token: string
  owner: string
  amount: string
}

const useLockList = () : LockData[] => {
  const { library } = useActiveWeb3React()
  const contract = getLockContract(library)
  const lockCountRes = useSingleCallResult(contract, 'totalNums')
  const lockCount = lockCountRes?.result?.[0]
  const args = lockCount && lockCount > 0 ? Array.from(new Array(parseInt(lockCount.toString())).keys()).map(value => [value.toString()]) : undefined
  const locks = useSingleContractMultipleData(contract, 'lockByIndex', args)
  return locks.map((value, index)=>{
    return {
      index: index.toString(),
      beginTime: value?.result?.beginTime,
      endTime: value?.result?.endTime,
      hasUnlocked: value?.result?.hasUnlocked,
      isLP: value?.result?.isLpToken,
      title: value?.result?.title,
      token: value?.result?.token,
      owner: value?.result?.owner,
      amount: value?.result?.amount
    }
  })
}

const useMyLockList = () : LockData[] => {
  const { library, account } = useActiveWeb3React()
  const contract = getLockContract(library)
  const lockCountRes = useSingleCallResult(contract, 'ownedNums', [account ?? undefined])
  const lockCount = lockCountRes?.result?.[0]
  const args = lockCount && lockCount > 0 ? Array.from(new Array(parseInt(lockCount.toString())).keys()).map(value => [account, value.toString()]) : undefined
  const lockIndexesRes = useSingleContractMultipleData(contract, 'ownedIndex', args)
  const indexArgs = lockIndexesRes.filter(value => {return value.result}).map(value => [value.result?.[0].toString()])
  const locks = useSingleContractMultipleData(contract, 'lockByIndex', indexArgs)

  return locks.map((value, index)=>{
    return {
      index: index.toString(),
      beginTime: value?.result?.beginTime,
      endTime: value?.result?.endTime,
      hasUnlocked: value?.result?.hasUnlocked,
      isLP: value?.result?.isLpToken,
      title: value?.result?.title,
      token: value?.result?.token,
      owner: value?.result?.owner,
      amount: value?.result?.amount
    }
  })
}

export { useLockList, useMyLockList }
