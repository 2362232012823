import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

interface LogoProps extends SvgProps {
  isDark: boolean;
}

const Logo: React.FC<LogoProps> = ({ isDark, ...props }) => {
  const textColor = isDark ? '#FFFFFF' : '#000000'
  return (
    <img alt="" src="/images/swap.png" width="40" height="40" />

    // <svg width="151" height="28" viewBox="0 0 151 28" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    //   <path d="M18.4464 12.2857C18.4464 8.48043 15.3398 5.39551 11.5076 5.39551C9.81647 5.3951 8.1841 6.01113 6.92033 7.12663C6.72532 7.298 6.54 7.47993 6.36522 7.67161C3.0245 10.7386 4.24389 15.3196 2.60276 16.9408C0.606079 18.9133 0.836005 21.9944 4.17058 22.5403C11.608 23.7585 16.0399 17.5098 16.0399 17.5098C17.1747 16.2827 18.4464 14.0822 18.4464 12.2844" fill="#FAD841" />
    //   <path d="M18.4464 12.2857C18.4464 8.48043 15.3398 5.39551 11.5076 5.39551C9.81647 5.3951 8.1841 6.01113 6.92033 7.12663C6.72532 7.298 6.54 7.47993 6.36522 7.67161C3.0245 10.7386 4.24389 15.3196 2.60276 16.9408C0.606079 18.9133 0.836005 21.9944 4.17058 22.5403C11.608 23.7585 16.0399 17.5098 16.0399 17.5098C17.1747 16.2827 18.4464 14.0822 18.4464 12.2844" stroke="black" />
    //   <path d="M14.7358 16.1454C14.7358 16.1454 11.0115 21.7536 3.29785 21.0755C3.71902 21.3655 3.60862 21.534 4.42306 21.6545C11.6126 22.6519 15.571 17.2619 15.571 17.2619C16.6801 16.0322 17.5269 14.4963 17.5201 13.0778C17.5201 12.3325 17.4807 11.3765 17.2171 10.6665C17.2171 10.6665 15.9999 14.8384 14.7358 16.1454Z" fill="#FAAC28" />
    //   <path d="M14.5686 6.32558C14.3244 6.32528 14.1059 6.17687 14.0195 5.9527C13.9331 5.72853 13.9969 5.47528 14.1797 5.31641C16.0575 3.6896 16.3417 0.552112 16.344 0.520971C16.3751 0.207602 16.6564 -0.0234278 16.976 0.001898C17.2957 0.0272238 17.5357 0.299546 17.5151 0.613746C17.5029 0.760694 17.1946 4.24301 14.9568 6.18123C14.8498 6.27433 14.7116 6.32558 14.5686 6.32526" fill="black" />
    //   <path d="M24.7724 5.76705C19.3787 0.664033 17.1445 5.93976 17.1445 5.93976C17.1445 5.93976 19.6321 11.1553 24.7724 5.79755C24.7724 5.76705 24.7724 5.76705 24.7724 5.76705Z" fill="#3BB777" stroke="black" strokeLinejoin="round" />
    //   <path d="M23.6412 5.81396H18.2148C18.2148 5.81396 19.7652 9.68993 23.5773 5.88483" fill="#229857" />
    //   <path d="M96.5199 8.89702C89.1272 8.20355 90.62 13.7349 90.62 13.7349C90.62 13.7349 95.7937 16.3085 96.5387 8.92104C96.5199 8.89702 96.5199 8.89702 96.5199 8.89702Z" fill="#3BB777" stroke="black" strokeLinejoin="round" />
    //   <path d="M95.6586 9.6312L91.3857 12.9761C91.3857 12.9761 94.9957 15.0724 95.6519 9.7264" fill="#229857" />
    //   <path fillRule="evenodd" clipRule="evenodd" d="M9.48796 8.17445C9.55879 7.95845 9.48984 7.7219 9.31329 7.57518C9.19777 7.47897 9.04799 7.43168 8.8969 7.44374C8.74581 7.4558 8.6058 7.52622 8.50769 7.63949C8.35768 7.81227 8.32869 8.05664 8.43423 8.25856C8.53978 8.46048 8.75907 8.58015 8.98978 8.56174C9.22049 8.54332 9.41713 8.39045 9.48796 8.17445ZM5.61001 13.2039C5.63174 13.2069 5.65366 13.2084 5.67561 13.2084L5.6743 13.2094C5.89108 13.2091 6.0749 13.0531 6.10629 12.8429C6.11022 12.8171 6.4999 10.3106 7.86213 9.26123C8.05235 9.11509 8.08568 8.84548 7.93659 8.65904C7.7875 8.47259 7.51244 8.43992 7.32223 8.58606C5.68742 9.84413 5.25838 12.6008 5.24099 12.7175C5.2241 12.8299 5.25349 12.9444 5.3227 13.0356C5.3919 13.1268 5.49525 13.1874 5.61001 13.2039Z" fill="#FEFEFE" />
    //   <mask id="path-10-outside-1" maskUnits="userSpaceOnUse" x="29.7725" y="5" width="121" height="23" fill="black">
    //     <rect fill="white" x="29.7725" y="5" width="121" height="23" />
    //     <path d="M41.7641 21.4476L41.7289 15.8952L39.0557 20.351H37.2267L34.5536 16.0866V21.4476H30.7725V6H34.1843L38.194 15.7559L42.0982 6H45.51L45.5452 21.4476H41.7641Z" />
    //     <path d="M51.7374 11.7526C53.4257 11.7526 54.7154 12.1297 55.6065 12.884C56.4975 13.6266 56.943 14.7754 56.943 16.3303V21.4476H53.2499V20.1944C52.7926 21.1459 51.884 21.6216 50.524 21.6216C49.7619 21.6216 49.1112 21.494 48.5718 21.2387C48.0442 20.9718 47.6398 20.6179 47.3584 20.177C47.0887 19.7244 46.9539 19.2196 46.9539 18.6627C46.9539 17.7228 47.3173 17.0091 48.0442 16.5218C48.7712 16.0344 49.8733 15.7907 51.3505 15.7907H52.9509C52.8219 15.0481 52.2181 14.6768 51.1395 14.6768C50.7057 14.6768 50.266 14.7464 49.8205 14.8856C49.375 15.0133 48.9939 15.1931 48.6774 15.4252L47.4111 12.8317C47.9622 12.4952 48.6246 12.2341 49.3984 12.0485C50.1839 11.8512 50.9636 11.7526 51.7374 11.7526ZM51.7198 19.2545C52.0012 19.2545 52.2533 19.179 52.4761 19.0282C52.6988 18.8773 52.863 18.6511 52.9685 18.3494V17.6879H51.9836C51.1747 17.6879 50.7702 17.9548 50.7702 18.4886C50.7702 18.7091 50.8522 18.8947 51.0164 19.0456C51.1922 19.1848 51.4267 19.2545 51.7198 19.2545Z" />
    //     <path d="M65.3437 11.7526C66.5278 11.7526 67.4775 12.1065 68.1927 12.8143C68.9196 13.5222 69.283 14.5897 69.283 16.017V21.4476H65.3085V16.6958C65.3085 15.6051 64.9157 15.0597 64.1302 15.0597C63.6847 15.0597 63.3212 15.2163 63.0398 15.5296C62.7702 15.8313 62.6353 16.3129 62.6353 16.9743V21.4476H58.6608V11.9266H62.4419V12.8666C62.8171 12.4952 63.2509 12.2167 63.7433 12.0311C64.2357 11.8454 64.7692 11.7526 65.3437 11.7526Z" />
    //     <path d="M81.9263 11.9266V19.5504C81.9263 21.3605 81.4163 23.806 80.3962 24.7111C79.3879 25.6278 77.9458 26.0862 76.0699 26.0862C75.1085 26.0862 74.2116 25.9818 73.3792 25.7729C72.5468 25.5756 71.8374 25.2855 71.2512 24.9026L72.5878 22.2221C72.963 22.5006 73.4378 22.7269 74.0123 22.901C74.5868 23.075 75.1379 23.162 75.6655 23.162C76.4627 23.162 77.0431 22.9938 77.4065 22.6573C77.77 22.3208 77.9517 20.7397 77.9517 20.0899V19.9159C77.3538 20.5889 76.4686 20.9254 75.2961 20.9254C74.4754 20.9254 73.7075 20.7397 72.9923 20.3684C72.2888 19.9855 71.7261 19.4459 71.304 18.7497C70.8819 18.0419 70.6709 17.2354 70.6709 16.3303C70.6709 15.4252 70.8819 14.6245 71.304 13.9283C71.7261 13.2321 72.2888 12.6983 72.9923 12.327C73.7075 11.9441 74.4754 11.7526 75.2961 11.7526C76.6093 11.7526 77.5589 12.1703 78.1452 13.0058V11.9266H81.9263ZM76.3513 17.862C76.8203 17.862 77.2072 17.7228 77.512 17.4443C77.8286 17.1542 77.9869 16.7828 77.9869 16.3303C77.9869 15.8777 77.8286 15.5122 77.512 15.2337C77.2072 14.9552 76.8203 14.816 76.3513 14.816C75.8706 14.816 75.472 14.9552 75.1554 15.2337C74.8506 15.5122 74.6982 15.8777 74.6982 16.3303C74.6982 16.7828 74.8565 17.1542 75.173 17.4443C75.4896 17.7228 75.8824 17.862 76.3513 17.862Z" />
    //     <path d="M88.7663 21.6216C87.6994 21.6216 86.738 21.4128 85.8821 20.995C85.038 20.5657 84.3756 19.9797 83.8949 19.2371C83.4142 18.4828 83.1738 17.6299 83.1738 16.6784C83.1738 15.7269 83.4142 14.8798 83.8949 14.1372C84.3756 13.3829 85.038 12.7969 85.8821 12.3792C86.738 11.9615 87.6994 11.7526 88.7663 11.7526C89.845 11.7526 90.8064 11.9615 91.6505 12.3792C92.5064 12.7969 93.1688 13.3829 93.6378 14.1372C94.1185 14.8798 94.3589 15.7269 94.3589 16.6784C94.3589 17.6299 94.1185 18.4828 93.6378 19.2371C93.1688 19.9797 92.5064 20.5657 91.6505 20.995C90.8064 21.4128 89.845 21.6216 88.7663 21.6216ZM88.7663 18.5582C89.2236 18.5582 89.5988 18.3958 89.8919 18.0709C90.185 17.7344 90.3315 17.2702 90.3315 16.6784C90.3315 16.0866 90.185 15.6283 89.8919 15.3034C89.5988 14.9784 89.2236 14.816 88.7663 14.816C88.3091 14.816 87.9339 14.9784 87.6408 15.3034C87.3477 15.6283 87.2011 16.0866 87.2011 16.6784C87.2011 17.2702 87.3477 17.7344 87.6408 18.0709C87.9339 18.3958 88.3091 18.5582 88.7663 18.5582Z" />
    //     <path d="M104.639 21.6216C103.818 21.6216 103.009 21.5346 102.212 21.3605C101.415 21.1865 100.77 20.9602 100.277 20.6817L101.368 18.0883C101.813 18.3552 102.341 18.564 102.951 18.7149C103.56 18.8657 104.152 18.9412 104.727 18.9412C105.184 18.9412 105.501 18.9063 105.676 18.8367C105.864 18.7555 105.958 18.6395 105.958 18.4886C105.958 18.3378 105.841 18.2333 105.606 18.1753C105.383 18.1173 105.014 18.0593 104.498 18.0012C103.713 17.9084 103.044 17.7924 102.493 17.6531C101.942 17.5023 101.456 17.218 101.034 16.8002C100.623 16.3825 100.418 15.7849 100.418 15.0075C100.418 14.3925 100.606 13.8413 100.981 13.3539C101.356 12.8666 101.913 12.4778 102.652 12.1877C103.402 11.8976 104.299 11.7526 105.342 11.7526C106.069 11.7526 106.779 11.8222 107.47 11.9615C108.174 12.0891 108.772 12.2806 109.264 12.5358L108.174 15.1293C107.33 14.6651 106.403 14.4331 105.395 14.4331C104.528 14.4331 104.094 14.5839 104.094 14.8856C104.094 15.0365 104.211 15.1467 104.445 15.2163C104.68 15.2743 105.049 15.3324 105.553 15.3904C106.339 15.4832 107.001 15.6051 107.541 15.7559C108.092 15.9068 108.572 16.191 108.983 16.6088C109.405 17.0265 109.616 17.6241 109.616 18.4016C109.616 18.9934 109.428 19.5329 109.053 20.0203C108.678 20.5077 108.115 20.8964 107.365 21.1865C106.614 21.4766 105.706 21.6216 104.639 21.6216Z" />
    //     <path d="M127.056 11.8388L123.714 21.4476H119.845L118.386 17.0439L116.873 21.4476H113.004L109.663 11.8388H113.426L115.079 16.9569L116.803 11.8388H120.18L121.85 17.0091L123.574 11.8388H127.056Z" />
    //     <path d="M131.787 11.7526C133.475 11.7526 134.765 12.1297 135.656 12.884C136.547 13.6266 136.993 14.7754 136.993 16.3303V21.4476H133.3V20.1944C132.842 21.1459 131.934 21.6216 130.574 21.6216C129.812 21.6216 129.161 21.494 128.622 21.2387C128.094 20.9718 127.69 20.6179 127.408 20.177C127.138 19.7244 127.004 19.2196 127.004 18.6627C127.004 17.7228 127.367 17.0091 128.094 16.5218C128.821 16.0344 129.923 15.7907 131.4 15.7907H133.001C132.872 15.0481 132.268 14.6768 131.189 14.6768C130.755 14.6768 130.316 14.7464 129.87 14.8856C129.425 15.0133 129.044 15.1931 128.727 15.4252L127.461 12.8317C128.012 12.4952 128.674 12.2341 129.448 12.0485C130.234 11.8512 131.013 11.7526 131.787 11.7526ZM131.77 19.2545C132.051 19.2545 132.303 19.179 132.526 19.0282C132.749 18.8773 132.913 18.6511 133.018 18.3494V17.6879H132.033C131.224 17.6879 130.82 17.9548 130.82 18.4886C130.82 18.7091 130.902 18.8947 131.066 19.0456C131.242 19.1848 131.476 19.2545 131.77 19.2545Z" />
    //     <path d="M145.13 11.7526C145.974 11.7526 146.748 11.9557 147.451 12.3618C148.166 12.7563 148.729 13.3307 149.139 14.085C149.561 14.8392 149.772 15.7095 149.772 16.6958C149.772 17.6821 149.561 18.5524 149.139 19.3067C148.729 20.0493 148.166 20.6237 147.451 21.0298C146.748 21.4244 145.974 21.6216 145.13 21.6216C144.063 21.6216 143.248 21.3489 142.685 20.8036V27H138.711V11.9266H142.492V12.7099C143.066 12.0717 143.945 11.7526 145.13 11.7526ZM144.18 18.5582C144.637 18.5582 145.012 18.3958 145.305 18.0709C145.599 17.746 145.745 17.2876 145.745 16.6958C145.745 16.104 145.599 15.6457 145.305 15.3208C145.012 14.9843 144.637 14.816 144.18 14.816C143.723 14.816 143.348 14.9843 143.054 15.3208C142.761 15.6457 142.615 16.104 142.615 16.6958C142.615 17.2876 142.761 17.746 143.054 18.0709C143.348 18.3958 143.723 18.5582 144.18 18.5582Z" />
    //   </mask>
    //   <path d="M41.7641 21.4476L41.7289 15.8952L39.0557 20.351H37.2267L34.5536 16.0866V21.4476H30.7725V6H34.1843L38.194 15.7559L42.0982 6H45.51L45.5452 21.4476H41.7641Z" fill="#FAD841" />
    //   <path d="M51.7374 11.7526C53.4257 11.7526 54.7154 12.1297 55.6065 12.884C56.4975 13.6266 56.943 14.7754 56.943 16.3303V21.4476H53.2499V20.1944C52.7926 21.1459 51.884 21.6216 50.524 21.6216C49.7619 21.6216 49.1112 21.494 48.5718 21.2387C48.0442 20.9718 47.6398 20.6179 47.3584 20.177C47.0887 19.7244 46.9539 19.2196 46.9539 18.6627C46.9539 17.7228 47.3173 17.0091 48.0442 16.5218C48.7712 16.0344 49.8733 15.7907 51.3505 15.7907H52.9509C52.8219 15.0481 52.2181 14.6768 51.1395 14.6768C50.7057 14.6768 50.266 14.7464 49.8205 14.8856C49.375 15.0133 48.9939 15.1931 48.6774 15.4252L47.4111 12.8317C47.9622 12.4952 48.6246 12.2341 49.3984 12.0485C50.1839 11.8512 50.9636 11.7526 51.7374 11.7526ZM51.7198 19.2545C52.0012 19.2545 52.2533 19.179 52.4761 19.0282C52.6988 18.8773 52.863 18.6511 52.9685 18.3494V17.6879H51.9836C51.1747 17.6879 50.7702 17.9548 50.7702 18.4886C50.7702 18.7091 50.8522 18.8947 51.0164 19.0456C51.1922 19.1848 51.4267 19.2545 51.7198 19.2545Z" fill="#FAD841" />
    //   <path d="M65.3437 11.7526C66.5278 11.7526 67.4775 12.1065 68.1927 12.8143C68.9196 13.5222 69.283 14.5897 69.283 16.017V21.4476H65.3085V16.6958C65.3085 15.6051 64.9157 15.0597 64.1302 15.0597C63.6847 15.0597 63.3212 15.2163 63.0398 15.5296C62.7702 15.8313 62.6353 16.3129 62.6353 16.9743V21.4476H58.6608V11.9266H62.4419V12.8666C62.8171 12.4952 63.2509 12.2167 63.7433 12.0311C64.2357 11.8454 64.7692 11.7526 65.3437 11.7526Z" fill="#FAD841" />
    //   <path d="M81.9263 11.9266V19.5504C81.9263 21.3605 81.4163 23.806 80.3962 24.7111C79.3879 25.6278 77.9458 26.0862 76.0699 26.0862C75.1085 26.0862 74.2116 25.9818 73.3792 25.7729C72.5468 25.5756 71.8374 25.2855 71.2512 24.9026L72.5878 22.2221C72.963 22.5006 73.4378 22.7269 74.0123 22.901C74.5868 23.075 75.1379 23.162 75.6655 23.162C76.4627 23.162 77.0431 22.9938 77.4065 22.6573C77.77 22.3208 77.9517 20.7397 77.9517 20.0899V19.9159C77.3538 20.5889 76.4686 20.9254 75.2961 20.9254C74.4754 20.9254 73.7075 20.7397 72.9923 20.3684C72.2888 19.9855 71.7261 19.4459 71.304 18.7497C70.8819 18.0419 70.6709 17.2354 70.6709 16.3303C70.6709 15.4252 70.8819 14.6245 71.304 13.9283C71.7261 13.2321 72.2888 12.6983 72.9923 12.327C73.7075 11.9441 74.4754 11.7526 75.2961 11.7526C76.6093 11.7526 77.5589 12.1703 78.1452 13.0058V11.9266H81.9263ZM76.3513 17.862C76.8203 17.862 77.2072 17.7228 77.512 17.4443C77.8286 17.1542 77.9869 16.7828 77.9869 16.3303C77.9869 15.8777 77.8286 15.5122 77.512 15.2337C77.2072 14.9552 76.8203 14.816 76.3513 14.816C75.8706 14.816 75.472 14.9552 75.1554 15.2337C74.8506 15.5122 74.6982 15.8777 74.6982 16.3303C74.6982 16.7828 74.8565 17.1542 75.173 17.4443C75.4896 17.7228 75.8824 17.862 76.3513 17.862Z" fill="#FAD841" />
    //   <path d="M88.7663 21.6216C87.6994 21.6216 86.738 21.4128 85.8821 20.995C85.038 20.5657 84.3756 19.9797 83.8949 19.2371C83.4142 18.4828 83.1738 17.6299 83.1738 16.6784C83.1738 15.7269 83.4142 14.8798 83.8949 14.1372C84.3756 13.3829 85.038 12.7969 85.8821 12.3792C86.738 11.9615 87.6994 11.7526 88.7663 11.7526C89.845 11.7526 90.8064 11.9615 91.6505 12.3792C92.5064 12.7969 93.1688 13.3829 93.6378 14.1372C94.1185 14.8798 94.3589 15.7269 94.3589 16.6784C94.3589 17.6299 94.1185 18.4828 93.6378 19.2371C93.1688 19.9797 92.5064 20.5657 91.6505 20.995C90.8064 21.4128 89.845 21.6216 88.7663 21.6216ZM88.7663 18.5582C89.2236 18.5582 89.5988 18.3958 89.8919 18.0709C90.185 17.7344 90.3315 17.2702 90.3315 16.6784C90.3315 16.0866 90.185 15.6283 89.8919 15.3034C89.5988 14.9784 89.2236 14.816 88.7663 14.816C88.3091 14.816 87.9339 14.9784 87.6408 15.3034C87.3477 15.6283 87.2011 16.0866 87.2011 16.6784C87.2011 17.2702 87.3477 17.7344 87.6408 18.0709C87.9339 18.3958 88.3091 18.5582 88.7663 18.5582Z" fill="#FAD841" />
    //   <path d="M104.639 21.6216C103.818 21.6216 103.009 21.5346 102.212 21.3605C101.415 21.1865 100.77 20.9602 100.277 20.6817L101.368 18.0883C101.813 18.3552 102.341 18.564 102.951 18.7149C103.56 18.8657 104.152 18.9412 104.727 18.9412C105.184 18.9412 105.501 18.9063 105.676 18.8367C105.864 18.7555 105.958 18.6395 105.958 18.4886C105.958 18.3378 105.841 18.2333 105.606 18.1753C105.383 18.1173 105.014 18.0593 104.498 18.0012C103.713 17.9084 103.044 17.7924 102.493 17.6531C101.942 17.5023 101.456 17.218 101.034 16.8002C100.623 16.3825 100.418 15.7849 100.418 15.0075C100.418 14.3925 100.606 13.8413 100.981 13.3539C101.356 12.8666 101.913 12.4778 102.652 12.1877C103.402 11.8976 104.299 11.7526 105.342 11.7526C106.069 11.7526 106.779 11.8222 107.47 11.9615C108.174 12.0891 108.772 12.2806 109.264 12.5358L108.174 15.1293C107.33 14.6651 106.403 14.4331 105.395 14.4331C104.528 14.4331 104.094 14.5839 104.094 14.8856C104.094 15.0365 104.211 15.1467 104.445 15.2163C104.68 15.2743 105.049 15.3324 105.553 15.3904C106.339 15.4832 107.001 15.6051 107.541 15.7559C108.092 15.9068 108.572 16.191 108.983 16.6088C109.405 17.0265 109.616 17.6241 109.616 18.4016C109.616 18.9934 109.428 19.5329 109.053 20.0203C108.678 20.5077 108.115 20.8964 107.365 21.1865C106.614 21.4766 105.706 21.6216 104.639 21.6216Z" fill="#FAD841" />
    //   <path d="M127.056 11.8388L123.714 21.4476H119.845L118.386 17.0439L116.873 21.4476H113.004L109.663 11.8388H113.426L115.079 16.9569L116.803 11.8388H120.18L121.85 17.0091L123.574 11.8388H127.056Z" fill="#FAD841" />
    //   <path d="M131.787 11.7526C133.475 11.7526 134.765 12.1297 135.656 12.884C136.547 13.6266 136.993 14.7754 136.993 16.3303V21.4476H133.3V20.1944C132.842 21.1459 131.934 21.6216 130.574 21.6216C129.812 21.6216 129.161 21.494 128.622 21.2387C128.094 20.9718 127.69 20.6179 127.408 20.177C127.138 19.7244 127.004 19.2196 127.004 18.6627C127.004 17.7228 127.367 17.0091 128.094 16.5218C128.821 16.0344 129.923 15.7907 131.4 15.7907H133.001C132.872 15.0481 132.268 14.6768 131.189 14.6768C130.755 14.6768 130.316 14.7464 129.87 14.8856C129.425 15.0133 129.044 15.1931 128.727 15.4252L127.461 12.8317C128.012 12.4952 128.674 12.2341 129.448 12.0485C130.234 11.8512 131.013 11.7526 131.787 11.7526ZM131.77 19.2545C132.051 19.2545 132.303 19.179 132.526 19.0282C132.749 18.8773 132.913 18.6511 133.018 18.3494V17.6879H132.033C131.224 17.6879 130.82 17.9548 130.82 18.4886C130.82 18.7091 130.902 18.8947 131.066 19.0456C131.242 19.1848 131.476 19.2545 131.77 19.2545Z" fill="#FAD841" />
    //   <path d="M145.13 11.7526C145.974 11.7526 146.748 11.9557 147.451 12.3618C148.166 12.7563 148.729 13.3307 149.139 14.085C149.561 14.8392 149.772 15.7095 149.772 16.6958C149.772 17.6821 149.561 18.5524 149.139 19.3067C148.729 20.0493 148.166 20.6237 147.451 21.0298C146.748 21.4244 145.974 21.6216 145.13 21.6216C144.063 21.6216 143.248 21.3489 142.685 20.8036V27H138.711V11.9266H142.492V12.7099C143.066 12.0717 143.945 11.7526 145.13 11.7526ZM144.18 18.5582C144.637 18.5582 145.012 18.3958 145.305 18.0709C145.599 17.746 145.745 17.2876 145.745 16.6958C145.745 16.104 145.599 15.6457 145.305 15.3208C145.012 14.9843 144.637 14.816 144.18 14.816C143.723 14.816 143.348 14.9843 143.054 15.3208C142.761 15.6457 142.615 16.104 142.615 16.6958C142.615 17.2876 142.761 17.746 143.054 18.0709C143.348 18.3958 143.723 18.5582 144.18 18.5582Z" fill="#FAD841" />
    //   <path d="M41.7641 21.4476L41.7289 15.8952L39.0557 20.351H37.2267L34.5536 16.0866V21.4476H30.7725V6H34.1843L38.194 15.7559L42.0982 6H45.51L45.5452 21.4476H41.7641Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M51.7374 11.7526C53.4257 11.7526 54.7154 12.1297 55.6065 12.884C56.4975 13.6266 56.943 14.7754 56.943 16.3303V21.4476H53.2499V20.1944C52.7926 21.1459 51.884 21.6216 50.524 21.6216C49.7619 21.6216 49.1112 21.494 48.5718 21.2387C48.0442 20.9718 47.6398 20.6179 47.3584 20.177C47.0887 19.7244 46.9539 19.2196 46.9539 18.6627C46.9539 17.7228 47.3173 17.0091 48.0442 16.5218C48.7712 16.0344 49.8733 15.7907 51.3505 15.7907H52.9509C52.8219 15.0481 52.2181 14.6768 51.1395 14.6768C50.7057 14.6768 50.266 14.7464 49.8205 14.8856C49.375 15.0133 48.9939 15.1931 48.6774 15.4252L47.4111 12.8317C47.9622 12.4952 48.6246 12.2341 49.3984 12.0485C50.1839 11.8512 50.9636 11.7526 51.7374 11.7526ZM51.7198 19.2545C52.0012 19.2545 52.2533 19.179 52.4761 19.0282C52.6988 18.8773 52.863 18.6511 52.9685 18.3494V17.6879H51.9836C51.1747 17.6879 50.7702 17.9548 50.7702 18.4886C50.7702 18.7091 50.8522 18.8947 51.0164 19.0456C51.1922 19.1848 51.4267 19.2545 51.7198 19.2545Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M65.3437 11.7526C66.5278 11.7526 67.4775 12.1065 68.1927 12.8143C68.9196 13.5222 69.283 14.5897 69.283 16.017V21.4476H65.3085V16.6958C65.3085 15.6051 64.9157 15.0597 64.1302 15.0597C63.6847 15.0597 63.3212 15.2163 63.0398 15.5296C62.7702 15.8313 62.6353 16.3129 62.6353 16.9743V21.4476H58.6608V11.9266H62.4419V12.8666C62.8171 12.4952 63.2509 12.2167 63.7433 12.0311C64.2357 11.8454 64.7692 11.7526 65.3437 11.7526Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M81.9263 11.9266V19.5504C81.9263 21.3605 81.4163 23.806 80.3962 24.7111C79.3879 25.6278 77.9458 26.0862 76.0699 26.0862C75.1085 26.0862 74.2116 25.9818 73.3792 25.7729C72.5468 25.5756 71.8374 25.2855 71.2512 24.9026L72.5878 22.2221C72.963 22.5006 73.4378 22.7269 74.0123 22.901C74.5868 23.075 75.1379 23.162 75.6655 23.162C76.4627 23.162 77.0431 22.9938 77.4065 22.6573C77.77 22.3208 77.9517 20.7397 77.9517 20.0899V19.9159C77.3538 20.5889 76.4686 20.9254 75.2961 20.9254C74.4754 20.9254 73.7075 20.7397 72.9923 20.3684C72.2888 19.9855 71.7261 19.4459 71.304 18.7497C70.8819 18.0419 70.6709 17.2354 70.6709 16.3303C70.6709 15.4252 70.8819 14.6245 71.304 13.9283C71.7261 13.2321 72.2888 12.6983 72.9923 12.327C73.7075 11.9441 74.4754 11.7526 75.2961 11.7526C76.6093 11.7526 77.5589 12.1703 78.1452 13.0058V11.9266H81.9263ZM76.3513 17.862C76.8203 17.862 77.2072 17.7228 77.512 17.4443C77.8286 17.1542 77.9869 16.7828 77.9869 16.3303C77.9869 15.8777 77.8286 15.5122 77.512 15.2337C77.2072 14.9552 76.8203 14.816 76.3513 14.816C75.8706 14.816 75.472 14.9552 75.1554 15.2337C74.8506 15.5122 74.6982 15.8777 74.6982 16.3303C74.6982 16.7828 74.8565 17.1542 75.173 17.4443C75.4896 17.7228 75.8824 17.862 76.3513 17.862Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M88.7663 21.6216C87.6994 21.6216 86.738 21.4128 85.8821 20.995C85.038 20.5657 84.3756 19.9797 83.8949 19.2371C83.4142 18.4828 83.1738 17.6299 83.1738 16.6784C83.1738 15.7269 83.4142 14.8798 83.8949 14.1372C84.3756 13.3829 85.038 12.7969 85.8821 12.3792C86.738 11.9615 87.6994 11.7526 88.7663 11.7526C89.845 11.7526 90.8064 11.9615 91.6505 12.3792C92.5064 12.7969 93.1688 13.3829 93.6378 14.1372C94.1185 14.8798 94.3589 15.7269 94.3589 16.6784C94.3589 17.6299 94.1185 18.4828 93.6378 19.2371C93.1688 19.9797 92.5064 20.5657 91.6505 20.995C90.8064 21.4128 89.845 21.6216 88.7663 21.6216ZM88.7663 18.5582C89.2236 18.5582 89.5988 18.3958 89.8919 18.0709C90.185 17.7344 90.3315 17.2702 90.3315 16.6784C90.3315 16.0866 90.185 15.6283 89.8919 15.3034C89.5988 14.9784 89.2236 14.816 88.7663 14.816C88.3091 14.816 87.9339 14.9784 87.6408 15.3034C87.3477 15.6283 87.2011 16.0866 87.2011 16.6784C87.2011 17.2702 87.3477 17.7344 87.6408 18.0709C87.9339 18.3958 88.3091 18.5582 88.7663 18.5582Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M104.639 21.6216C103.818 21.6216 103.009 21.5346 102.212 21.3605C101.415 21.1865 100.77 20.9602 100.277 20.6817L101.368 18.0883C101.813 18.3552 102.341 18.564 102.951 18.7149C103.56 18.8657 104.152 18.9412 104.727 18.9412C105.184 18.9412 105.501 18.9063 105.676 18.8367C105.864 18.7555 105.958 18.6395 105.958 18.4886C105.958 18.3378 105.841 18.2333 105.606 18.1753C105.383 18.1173 105.014 18.0593 104.498 18.0012C103.713 17.9084 103.044 17.7924 102.493 17.6531C101.942 17.5023 101.456 17.218 101.034 16.8002C100.623 16.3825 100.418 15.7849 100.418 15.0075C100.418 14.3925 100.606 13.8413 100.981 13.3539C101.356 12.8666 101.913 12.4778 102.652 12.1877C103.402 11.8976 104.299 11.7526 105.342 11.7526C106.069 11.7526 106.779 11.8222 107.47 11.9615C108.174 12.0891 108.772 12.2806 109.264 12.5358L108.174 15.1293C107.33 14.6651 106.403 14.4331 105.395 14.4331C104.528 14.4331 104.094 14.5839 104.094 14.8856C104.094 15.0365 104.211 15.1467 104.445 15.2163C104.68 15.2743 105.049 15.3324 105.553 15.3904C106.339 15.4832 107.001 15.6051 107.541 15.7559C108.092 15.9068 108.572 16.191 108.983 16.6088C109.405 17.0265 109.616 17.6241 109.616 18.4016C109.616 18.9934 109.428 19.5329 109.053 20.0203C108.678 20.5077 108.115 20.8964 107.365 21.1865C106.614 21.4766 105.706 21.6216 104.639 21.6216Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M127.056 11.8388L123.714 21.4476H119.845L118.386 17.0439L116.873 21.4476H113.004L109.663 11.8388H113.426L115.079 16.9569L116.803 11.8388H120.18L121.85 17.0091L123.574 11.8388H127.056Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M131.787 11.7526C133.475 11.7526 134.765 12.1297 135.656 12.884C136.547 13.6266 136.993 14.7754 136.993 16.3303V21.4476H133.3V20.1944C132.842 21.1459 131.934 21.6216 130.574 21.6216C129.812 21.6216 129.161 21.494 128.622 21.2387C128.094 20.9718 127.69 20.6179 127.408 20.177C127.138 19.7244 127.004 19.2196 127.004 18.6627C127.004 17.7228 127.367 17.0091 128.094 16.5218C128.821 16.0344 129.923 15.7907 131.4 15.7907H133.001C132.872 15.0481 132.268 14.6768 131.189 14.6768C130.755 14.6768 130.316 14.7464 129.87 14.8856C129.425 15.0133 129.044 15.1931 128.727 15.4252L127.461 12.8317C128.012 12.4952 128.674 12.2341 129.448 12.0485C130.234 11.8512 131.013 11.7526 131.787 11.7526ZM131.77 19.2545C132.051 19.2545 132.303 19.179 132.526 19.0282C132.749 18.8773 132.913 18.6511 133.018 18.3494V17.6879H132.033C131.224 17.6879 130.82 17.9548 130.82 18.4886C130.82 18.7091 130.902 18.8947 131.066 19.0456C131.242 19.1848 131.476 19.2545 131.77 19.2545Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    //   <path d="M145.13 11.7526C145.974 11.7526 146.748 11.9557 147.451 12.3618C148.166 12.7563 148.729 13.3307 149.139 14.085C149.561 14.8392 149.772 15.7095 149.772 16.6958C149.772 17.6821 149.561 18.5524 149.139 19.3067C148.729 20.0493 148.166 20.6237 147.451 21.0298C146.748 21.4244 145.974 21.6216 145.13 21.6216C144.063 21.6216 143.248 21.3489 142.685 20.8036V27H138.711V11.9266H142.492V12.7099C143.066 12.0717 143.945 11.7526 145.13 11.7526ZM144.18 18.5582C144.637 18.5582 145.012 18.3958 145.305 18.0709C145.599 17.746 145.745 17.2876 145.745 16.6958C145.745 16.104 145.599 15.6457 145.305 15.3208C145.012 14.9843 144.637 14.816 144.18 14.816C143.723 14.816 143.348 14.9843 143.054 15.3208C142.761 15.6457 142.615 16.104 142.615 16.6958C142.615 17.2876 142.761 17.746 143.054 18.0709C143.348 18.3958 143.723 18.5582 144.18 18.5582Z" stroke="black" strokeWidth="2" mask="url(#path-10-outside-1)" />
    // </svg>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
