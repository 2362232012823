import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="15" height="13" viewBox="0 0 15 13" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.875 9.45455C0.825 9.45455 0 10.2345 0 11.2273C0 12.22 0.825 13 1.875 13C2.925 13 3.75 12.22 3.75 11.2273C3.75 10.3055 2.925 9.45455 1.875 9.45455ZM11.5625 5.82755C12.4225 5.82755 13.75 6.11945 13.75 6.55673V2.91436C13.75 2.11191 13.4375 1.23736 12.5787 1.23736H8.6725L7.11 4.88091H4.375V2.18636C4.375 1.96773 4.45375 1.74909 4.21875 1.60373L2.8125 0.219818C2.5 -0.0732727 2.11 -0.0732727 1.7975 0.219818C1.485 0.510545 1.33 0.947818 1.64125 1.23855L2.8125 2.47709V4.88091H2.4225C1.95375 4.88091 1.25 5.46355 1.25 5.82755V8.086C2.03125 8.086 1.71875 8.01509 1.875 8.01509C3.36 8.01509 4.6875 9.03382 4.6875 10.4189C4.6875 10.5643 4.76625 10.6364 4.76625 10.6364H8.28125C8.04625 10.6364 7.89 9.83509 7.89 9.32455C7.8125 7.358 9.4525 5.82755 11.5625 5.82755ZM8.75 10.0455C8.75231 10.8284 9.08229 11.5786 9.66784 12.1322C10.2534 12.6858 11.0469 12.9978 11.875 13C12.7031 12.9978 13.4966 12.6858 14.0822 12.1322C14.6677 11.5786 14.9977 10.8284 15 10.0455C14.9977 9.26253 14.6677 8.5123 14.0822 7.95869C13.4966 7.40507 12.7031 7.09309 11.875 7.09091C11.0469 7.09309 10.2534 7.40507 9.66784 7.95869C9.08229 8.5123 8.75231 9.26253 8.75 10.0455Z" />
    </Svg>
  );
};

export default Icon;
