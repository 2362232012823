import tokens from './tokens'
import farms from './farms'
import { Ifo, Token } from './types'

export const IfosDefaultActiveKey = []
export const ifoMasterAddress = '0x902F930C53A7B475cAF9782ea02d00EaDaFEb20E'
export const ifoProjectKey = 'id'
const ifos: Ifo[] = [

]

export default ifos
