export const API_BASE_URL =
  process.env.REACT_APP_BASE_URL
 console.log(API_BASE_URL, process.env)
export async function checkBlacklist(tokenAddress:string): Promise<boolean> {
    const res = await fetch(`${API_BASE_URL}/ratecoin?tokenAddress=${tokenAddress}`,
    {
      headers: {
        chainType: 'tx'
      }
    })
    if(!res.ok){
      throw Error('fetch ratecoin error')
      
    }
    const json = await res.json()
    // console.log('resJson',res, json)
    return json?.data?.rateCoin
    
}

export async function reportBlacklist(tokenAddress:string, coinName:string ): Promise<void> {
  const res = await fetch(`${API_BASE_URL}/report?tokenAddress=${tokenAddress}&coinName=${coinName}`,
  {
    headers: {
      chainType: 'tx'
    }
  })
  const json = await res.json()
  return json.data
}

