import { checkBlacklist } from 'service/tokenBlacklist'
import { useRequest } from 'ahooks'

export const useAvailableCurrency = (address: string):boolean =>  {
  // const dispatch = useDispatch<AppDispatch>()
  // const { availableCurrency } = useSwapState()
  
  const {
    loading,
    data,
    refresh
  } = useRequest(async () => {

    if(!address) return true
    if(address === 'TX')return false
    // if (availableCurrency[address]!== undefined){
    //   return availableCurrency[address]
    // }
    return checkBlacklist(address)
  }, {
    cacheKey: `AvailableCurrency${address}`,
    debounceWait: 300,
    retryCount: 3,
    refreshDeps: [address]
  })
  // dispatch(updateAvailableCurrency({ address, available: data }))
  
  
  return !data
  
 
}

export default useAvailableCurrency
