import React, { useState } from 'react'
import styled from 'styled-components'
import { ExpandableLabel, Divider, Flex, FlexProps, Text } from 'uikit'
import useI18n from 'hooks/useI18n'
import useIntl from 'hooks/useIntl'

interface FoldableTextProps extends FlexProps {
  title?: string
}

const Wrapper = styled(Flex)`
  cursor: pointer;
`

const StyledExpandableLabelWrapper = styled(Flex)`
  button {
    align-items: center;
    justify-content: flex-start;
  }
`

const StyledChildrenFlex = styled(Flex)<{ isExpanded?: boolean }>`
  overflow: hidden;
  height: ${({ isExpanded }) => (isExpanded ? '100%' : '0px')};
  padding-bottom: ${({ isExpanded }) => (isExpanded ? '16px' : '0px')};
`

const FoldableText: React.FC<FoldableTextProps> = ({ title, children, ...props }) => {
  const TranslateString = useI18n()
  const [isExpanded, setIsExpanded] = useState(false)
  const intl = useIntl()

  return (
    <Wrapper {...props} flexDirection="column" onClick={() => setIsExpanded(!isExpanded)}>
      <Flex justifyContent="space-between" alignItems="center" pb="16px">
        <Text bold>{title}</Text>
        <StyledExpandableLabelWrapper>
          <ExpandableLabel expanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? intl('farms.detailsHide') : intl('farms.detailsOpen')}
          </ExpandableLabel>
        </StyledExpandableLabelWrapper>
      </Flex>
      <StyledChildrenFlex isExpanded={isExpanded} flexDirection="column">
        {children}
      </StyledChildrenFlex>
      <Divider variant="gradual" style={{ margin: 0, height: 1 }} />
    </Wrapper>
  )
}

export default FoldableText
