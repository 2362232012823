import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M14.0455 16.9258H6.62088C5.56939 16.9258 4.74609 16.2904 4.74609 15.479V11.3074C4.74609 10.9449 5.03991 10.6511 5.40234 10.6511C5.76477 10.6511 6.05859 10.9449 6.05859 11.3074V15.4417C6.1108 15.4969 6.3032 15.6133 6.62088 15.6133H14.0469C14.3646 15.6133 14.557 15.4984 14.6092 15.4417V11.3074C14.6092 10.9449 14.9031 10.6511 15.2655 10.6511C15.6279 10.6511 15.9217 10.9449 15.9217 11.3074V15.479C15.9202 16.2904 15.0969 16.9258 14.0455 16.9258Z"/>
      <path d="M10.3433 12.5423C10.1778 12.5423 10.0137 12.52 9.85263 12.4737L7.15007 11.6952C7.1113 11.6832 7.07103 11.6788 7.03225 11.6818L5.10377 11.8041C4.73686 11.8279 4.37742 11.6743 4.12387 11.3835C3.83005 11.0494 3.70924 10.5736 3.80022 10.1142L4.57877 6.21251C4.77266 5.24305 5.5184 4.56592 6.3924 4.56592H14.2734C15.1474 4.56592 15.8931 5.24305 16.087 6.21251L16.8656 10.1142C16.9565 10.5736 16.8357 11.0479 16.5419 11.385C16.2884 11.6758 15.9289 11.8294 15.562 11.8055L13.632 11.6832C13.5918 11.6803 13.553 11.6847 13.5142 11.6967L10.8385 12.4722C10.6759 12.5185 10.5089 12.5423 10.3433 12.5423ZM7.05462 10.3693C7.20973 10.3693 7.36335 10.3916 7.5125 10.4349L10.2151 11.2119C10.3001 11.2358 10.3866 11.2358 10.4716 11.2119L13.1473 10.4364C13.3322 10.3827 13.5232 10.3618 13.7141 10.3737L15.571 10.4916C15.5814 10.4647 15.5889 10.4244 15.5784 10.3722L14.7999 6.47053C14.7313 6.12749 14.5105 5.87842 14.2734 5.87842H6.3924C6.15526 5.87842 5.93452 6.12749 5.86591 6.47053L5.08736 10.3722C5.07692 10.4244 5.08587 10.4647 5.09482 10.4916L6.94872 10.3737C6.98452 10.3707 7.01882 10.3693 7.05462 10.3693ZM15.6455 10.496H15.6485H15.6455Z"/>
      <path d="M10.3438 9.76675C9.98137 9.76675 9.68755 9.47293 9.68755 9.1105V7.46838C9.68755 7.10595 9.98137 6.81213 10.3438 6.81213C10.7062 6.81213 11.0001 7.10595 11.0001 7.46838V9.11199C11.0001 9.47293 10.7062 9.76675 10.3438 9.76675ZM13.1657 9.39537C12.854 9.39537 12.5765 9.17165 12.5199 8.85396L12.2932 7.58323C12.229 7.22676 12.4677 6.88521 12.8241 6.82257C13.1836 6.75844 13.5221 6.99708 13.5848 7.35354L13.8115 8.62428C13.8756 8.98074 13.637 9.32229 13.2805 9.38493C13.2417 9.3909 13.203 9.39537 13.1657 9.39537ZM7.45183 9.39537C7.41454 9.39537 7.37576 9.39239 7.33847 9.38493C6.98052 9.32229 6.74337 8.98223 6.80452 8.62577L7.02675 7.35503C7.0894 6.99857 7.42945 6.75993 7.78592 6.82108C8.14387 6.88372 8.38102 7.22378 8.31987 7.58024L8.09764 8.85098C8.04245 9.17016 7.76504 9.39537 7.45183 9.39537Z"/>
    </Svg>
  );
};

export default Icon;
