import React, { useCallback, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { CurrencyAmount, TokenAmount } from '@mangoswap-libs/sdk'
import moment from 'moment'
import { TransactionResponse } from '@ethersproject/providers'
import { LockData, useLockList } from '../../hooks/useLock'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AutoColumn } from '../../components/Column'
import { Button, Divider, Text as UIKitText, Text } from '../../uikit'
import CurrencyLogo from '../../components/CurrencyLogo'
import { usePairTokens, useToken } from '../../hooks/Tokens'
import Row, { AutoRow, RowBetween, RowFlat } from '../../components/Row'
import { calculateGasMargin, getLockContract } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import useIntl from '../../hooks/useIntl'
import { useIsMobile } from '../../uikit/theme/base'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 24px;
  overflow: scroll;
`

const ListWrapper = styled.div`
  padding: 8px 24px;
  background: #ffffff;
  width: 80%;
  overflow: scroll;
`

const LockView = styled.div`
  padding: 6px 0;
`

const Header = () => {
  return (
    <LockView>
      <RowBetween>
        <AutoRow>
          <Text>Liquidity token</Text>
        </AutoRow>
        <AutoRow>
          <Text>Amount</Text>
        </AutoRow>
        <AutoRow>
          <Text>Unlock Time</Text>
        </AutoRow>
        <AutoRow>
          <Text>Status</Text>
        </AutoRow>
      </RowBetween>
    </LockView>
  )
}

const LockItem = ({
                    lock,
                    onSelect
                  }: { lock: LockData, onSelect: (index: string, amount: CurrencyAmount) => void }) => {
  const { account } = useActiveWeb3React()
  const intl = useIntl()
  const isMobile = useIsMobile()
  const theme = useContext(ThemeContext)
  const token = useToken(lock?.token)
  const { token0, token1 } = usePairTokens(lock?.token && lock.isLP ? lock?.token : undefined)
  const amount = token && lock?.amount ? new TokenAmount(token, lock?.amount?.toString()) : undefined
  return (
    <LockView>
      {isMobile ? (
        <AutoColumn gap='12px'>
          <RowBetween>
            {lock.isLP ? <DoubleCurrencyLogo size={42} currency0={token0} currency1={token1} /> :
              <CurrencyLogo size='42px' currency={token} />}
            <AutoColumn gap='8px'>
              {lock.isLP ? <>
                <Text>{token0?.name?.toString()} / {token1?.name?.toString()}</Text>
                <Text color={theme.colors.textSubtle}>{token1?.symbol?.toString()} / {token1?.symbol?.toString()}</Text>
              </> : <>
                <Text>{token?.name?.toString()}</Text>
                <Text color={theme.colors.textSubtle}>{token?.symbol?.toString()}</Text>
              </>}
            </AutoColumn>
          </RowBetween>
          <RowBetween>
            <Text>{intl('lock.amount')}:</Text> <Text>{amount?.toSignificant(4, { groupSeparator: ',' })}</Text>
          </RowBetween>
          <RowBetween>
            <Text> {intl('lock.time')}:</Text> <Text>{moment(lock?.endTime * 1000).format('YYYY/MM/DD, h:mm:ss a').toString()}</Text>
          </RowBetween>
          <AutoRow>
            {lock.owner === account ?
              <Button disabled={lock.hasUnlocked} onClick={() => {
                onSelect(lock.index, amount)
              }}>{lock.hasUnlocked ? intl('lock.claimed') : intl('lock.claim')}</Button>
              : <Button disabled>{lock.hasUnlocked ? intl('lock.unlocked') : intl('lock.locked')}</Button>}
          </AutoRow>

        </AutoColumn>
      ) :(
        <RowBetween>
          <AutoRow gap='8px'>
            {lock.isLP ? <DoubleCurrencyLogo size={42} currency0={token0} currency1={token1} /> :
              <CurrencyLogo size='42px' currency={token} />}
            <AutoColumn gap='8px'>
              {lock.isLP ? <>
                <Text>{token0?.name?.toString()} / {token1?.name?.toString()}</Text>
                <Text color={theme.colors.textSubtle}>{token1?.symbol?.toString()} / {token1?.symbol?.toString()}</Text>
              </> : <>
                <Text>{token?.name?.toString()}</Text>
                <Text color={theme.colors.textSubtle}>{token?.symbol?.toString()}</Text>
              </>}
            </AutoColumn>
          </AutoRow>
          <AutoRow>
            <Text>{amount?.toSignificant(4, { groupSeparator: ',' })}</Text>
          </AutoRow>
          <AutoRow>
            <Text>{moment(lock?.endTime * 1000).format('YYYY/MM/DD, h:mm:ss a').toString()}</Text>
          </AutoRow>

          <AutoRow>
            {lock.owner === account ?
              <Button disabled={lock.hasUnlocked || lock.endTime > Date.now() / 1000} onClick={() => {
                onSelect(lock.index, amount)
              }}>{lock.hasUnlocked ? intl('lock.claimed') : intl('lock.claim')}</Button>
              : <Button disabled>{lock.hasUnlocked ? intl('lock.unlocked') : intl('lock.locked')}</Button>}
          </AutoRow>
        </RowBetween>
      )}
      <Divider />
    </LockView>
  )
}

export default function List() {
  const { account, library, chainId } = useActiveWeb3React()
  const locks = useLockList()
  const isMobile = useIsMobile()
  const addTransaction = useTransactionAdder()
  const intl = useIntl()

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [txHash, setTxHash] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [unLockAmount, setUnLockAmount] = useState<CurrencyAmount | undefined>(undefined)
  const [unLockIndex, setUnLockIndex] = useState<string | undefined>(undefined)

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    setTxHash('')
  }, [])


  const onUnLock = async () => {
    if (!chainId || !library || !account) return
    // console.log('---getRouterContract--- ', chainId, library,  account)
    const contract = getLockContract(library, account)
    // console.log('router', router)
    const estimate = contract.estimateGas.unlock
    const method: (...args: any) => Promise<TransactionResponse> = contract.unlock
    const args: Array<string | string[] | number> = [unLockIndex]

    setAttemptingTxn(true)
    await estimate(...args)
      .then((estimatedGasLimit) =>
        method(...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then((response) => {
          // console.log('---response--', response)
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: intl('lock.unlock')
          })

          setTxHash(response.hash)
        })
      )
      .catch((e) => {
        // console.log('---Error--', e)
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (e?.code !== 4001) {
          console.error(e)
        }
      })
  }

  const modalHeader = () => {
    return (
      <AutoColumn gap='20px'>
        <RowFlat style={{ marginTop: '20px' }}>
          <UIKitText fontSize='48px' mr='8px'>
            {unLockAmount?.toSignificant(6)} {unLockAmount?.currency?.symbol}
          </UIKitText>
        </RowFlat>
        <UIKitText small textAlign='left' padding='8px 0 0 0 ' style={{ fontStyle: 'italic' }}>
          {intl({
            id: 'lock.unlockTip',
            data: { amount: unLockAmount?.toSignificant(), token: unLockAmount?.currency?.symbol }
          })}
        </UIKitText>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <>
        <Button mt='20px' onClick={onUnLock} variant='primary'>
          {intl('lock.unlock')}
        </Button>
      </>
    )
  }

  return (
    <Wrapper>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => (
          <ConfirmationModalContent
            title={intl('lock.unlock')}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={
          intl({
            id:
              'lock.unlocking',
            data: { amount: unLockAmount?.toSignificant(), token: unLockAmount?.currency?.symbol }
          })}
      />
      <ListWrapper>
        {isMobile ? null : <Header />}
        {locks.map(value => {
          return (
            <LockItem onSelect={(index, amount) => {
              setUnLockIndex(index)
              setUnLockAmount(amount)
              setShowConfirm(true)
            }} lock={value} />)
        })}
      </ListWrapper>
    </Wrapper>
  )
}
