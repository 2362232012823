import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} style={{fill: 'none'}}>
      <path d="M12.4494 5.81824C11.8602 5.81824 11.3826 6.29587 11.3826 6.88506V7.73466C11.3826 7.80546 11.336 7.86764 11.2685 7.88915C10.4086 8.16329 9.62982 8.6193 8.97744 9.21197C8.9251 9.25952 8.84806 9.26871 8.78682 9.23335L8.03917 8.8017C7.52892 8.5071 6.87646 8.68193 6.58186 9.19218L5.87064 10.424C5.57605 10.9343 5.75088 11.5868 6.26113 11.8814L7.00392 12.3102C7.06522 12.3456 7.09578 12.417 7.08068 12.4862C6.98693 12.9157 6.93754 13.3618 6.93754 13.8194C6.93754 14.2789 6.98733 14.7267 7.08181 15.1578C7.09697 15.227 7.06642 15.2985 7.00507 15.3339L6.27139 15.7575C5.76114 16.0521 5.58631 16.7045 5.88091 17.2148L6.59212 18.4467C6.88672 18.9569 7.53918 19.1317 8.04943 18.8371L8.7908 18.4091C8.852 18.3738 8.92898 18.3829 8.98133 18.4304C9.63291 19.0213 10.4103 19.4761 11.2685 19.7497C11.336 19.7712 11.3826 19.8334 11.3826 19.9042V20.7538C11.3826 21.343 11.8602 21.8206 12.4494 21.8206H13.8719C14.461 21.8206 14.9387 21.343 14.9387 20.7538V19.9042C14.9387 19.8334 14.9853 19.7712 15.0527 19.7497C15.9172 19.4742 16.6996 19.0148 17.354 18.4177C17.4064 18.3699 17.4836 18.3606 17.5449 18.396L18.2718 18.8157C18.782 19.1103 19.4345 18.9355 19.7291 18.4252L20.4403 17.1934C20.7349 16.6831 20.5601 16.0307 20.0498 15.7361L19.3204 15.3149C19.2592 15.2796 19.2287 15.2084 19.2436 15.1393C19.3354 14.7139 19.3838 14.2723 19.3838 13.8194C19.3838 13.3683 19.3358 12.9285 19.2447 12.5047C19.2298 12.4356 19.2604 12.3645 19.3216 12.3292L20.0601 11.9028C20.5703 11.6082 20.7452 10.9557 20.4506 10.4455L19.7394 9.21361C19.4448 8.70336 18.7923 8.52853 18.2821 8.82313L17.5489 9.24642C17.4875 9.28187 17.4102 9.27254 17.3579 9.22471C16.7027 8.62582 15.9189 8.16522 15.0527 7.88912C14.9853 7.86762 14.9387 7.80544 14.9387 7.73464V6.88506C14.9387 6.29587 14.461 5.81824 13.8719 5.81824L12.4494 5.81824Z" stroke="#828282" strokeWidth="1.20018"/>
      <circle cx="13.1612" cy="13.8194" r="2.66706" stroke="#828282" strokeWidth="1.28019"/>
    </Svg>
  );
};

export default Icon;
